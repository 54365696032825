import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment from 'moment';

const state = {
    solicitud: false,

    fechaUltimaEdicion: '1900-01-01',
    programa: '',
    nombre: '',
    tipoDocumento: '',
    numeroDocumento: '',
    email: '',
    solicitudEstado: '',
    departamento: '',
    proceso: '',
    procesoEstado: '',
};

const mutations = {
    updateField,

    'INICIALIZAR' (state) {
        state.solicitud = false;

        state.fechaUltimaEdicion = '1900-01-01';
        state.programa = null;
        state.nombre = '';
        state.tipoDocumento = '';
        state.numeroDocumento = '';
        state.email = '';
        state.solicitudEstado = '';
        state.departamento = '';
        state.proceso = '';
        state.procesoEstado = '';
    },
    'SET_SOLICITUD'(state, solicitud) {
        state.solicitud = true;
        
        state.fechaUltimaEdicion = moment(solicitud.fechaUltimaEdicion).format('YYYY-MM-DD HH:mm:ss');
        state.programa = solicitud.programa;
        state.nombre = solicitud.nombre;
        state.tipoDocumento = solicitud.tipoDocumento;
        state.numeroDocumento = solicitud.numeroDocumento;
        state.email = solicitud.email;
        state.solicitudEstado = solicitud.solicitudEstado;
        state.departamento = solicitud.departamento;
        state.proceso = solicitud.proceso;
        state.procesoEstado = solicitud.procesoEstado;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar( { commit } ) {
        commit('INICIALIZAR');
    },
    getDatosSolicitud( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            let solicitudConsulta = {
                email: params.email,
                token: params.token
            }

            apiAxios.post(`public/solicitud/consulta`, solicitudConsulta) 
                .then(res => {
                    if (res.status == 200) {
                        commit('SET_SOLICITUD', res.data);
                    }
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}